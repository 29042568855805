import { useEffect } from 'react'
import { QUERY_ASSESSMENT_EVENTS, QUERY_EDPERIODS } from '../../../services/graphql/queries'
import { useCustomLazyQuery, useCustomQuery } from '../../../services/graphql/utils'
import Loader from '../../_common/loader/Loader'
import AssessmentActions from './AssessmentActions'
import AssessmentsTable from './AssessmentTable'

const Assessments = () => {
  const [selectedEvents, setSelectedEvents] = useState([])
  const edPeriod = useCustomQuery(QUERY_EDPERIODS)
  const events = useCustomLazyQuery(QUERY_ASSESSMENT_EVENTS)
  const pendingUpdates = events.data?.reduce((arr, d) => {
    const isSelected = Boolean(selectedEvents.find((e) => e.asmtEventId === d.asmtEventId))
    const shouldUpdate = (!d.active && isSelected) || (d.active && !isSelected)
    return shouldUpdate ? [...arr, { ...d, active: isSelected }] : arr
  }, [])

  useEffect(() => {
    setSelectedEvents(events.data?.filter((e) => e.active) || [])
  }, [events.data])

  const onEventUpdate = (updates) => setSelectedEvents(updates)

  const onEdPeriodSelection = (value) => {
    if (value) events.fetch({ variables: { educationPeriodCd: value } })
  }

  const getErrorProps = (message, container) =>
    message ? { title: 'Unable to Load Assessments', message, container } : undefined

  return (
    <Loader
      loaded={Boolean(edPeriod.data)}
      loading={edPeriod.loading}
      spinnerCenter
      error={getErrorProps(edPeriod.error)}
    >
      <div className="container">
        <h1 className="cb-h3 cb-roboto-light cb-margin-bottom-24">Assessments</h1>
        <AssessmentActions
          pendingUpdates={pendingUpdates}
          onEdPeriodSelection={onEdPeriodSelection}
          currentEdPeriod={edPeriod.data?.find((d) => d.current)?.code}
        />
        <Loader loading={events.loading} error={getErrorProps(events.error, false)} spinnerCenter loaded>
          <AssessmentsTable events={events.data} selectedEvents={selectedEvents} onSelection={onEventUpdate} />
        </Loader>
        <div className="cb-margin-top-24">
          {events.data ? <AssessmentActions pendingUpdates={pendingUpdates} /> : null}
        </div>
      </div>
    </Loader>
  )
}

export default Assessments
