import { NakedButton, PrimaryButton } from '@cb/apricot-react-button'
import { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { QUERY_ORG, QUERY_SEARCH_CRITERIA } from '../../services/graphql/queries'
import { useCustomLazyQuery, useCustomQuery } from '../../services/graphql/utils'
import { isValid, updateCriteria } from '../../services/session/findstudents'
import { getAllowedSchools } from '../../utils/auth'
import { ERROR } from '../../utils/constants'
import { FIND_ROUTES } from '../../utils/navigation'
import ActionBar from '../_common/actionbar/ActionBar'
import Loader from '../_common/loader/Loader'
import Stepper from '../_common/stepper/Stepper'

import './findstudents.scss'

const FindStudents = () => {
  const { data, loading, error } = useCustomQuery(QUERY_SEARCH_CRITERIA)
  const { fetch, ...org } = useCustomLazyQuery(QUERY_ORG)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const availableRoutes = FIND_ROUTES.filter((s) => !s.authCheck || s.authCheck())
  const routeIndex = availableRoutes.findIndex((r) => r.path === pathname)
  const selectedIndex = routeIndex !== -1 ? routeIndex : 0
  const { component: Component, nextPath, continueBtnText, path } = availableRoutes[selectedIndex]
  const next = availableRoutes[selectedIndex + 1] || { path: nextPath }
  const prev = availableRoutes[selectedIndex - 1]
  const firstInvalidPath = data ? availableRoutes.find((a) => !isValid(a.path))?.path : true
  const isCurrentPathValid = data ? isValid(path) : true
  const redirect =
    pathname !== path && isCurrentPathValid
      ? path
      : pathname !== firstInvalidPath && !isCurrentPathValid
        ? firstInvalidPath
        : undefined

  useEffect(() => {
    if (org.data) {
      const { orgName, aiCode, orgId } = org.data || {}
      updateCriteria({
        org: { selections: [{ value: orgId, label: `${orgName} (${aiCode})` }] },
      })
    }
  }, [org.data])

  useEffect(() => {
    const schools = getAllowedSchools()
    if (schools.length === 1 && data && !data.org?.selections.find((s) => s.value === schools[0]))
      fetch({ variables: { org: schools[0] } })
  }, [fetch, data])

  if (redirect) return <Navigate to={redirect} replace />

  return (
    <Loader
      loaded={Boolean(data)}
      loading={loading || org.loading}
      spinnerCenter
      error={error || org.error ? ERROR.DEFAULT : undefined}
    >
      <div className="appotential-step">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="cb-margin-bottom-8">
                <Stepper steps={availableRoutes} selectedIndex={selectedIndex} />
              </div>
              <Component criteria={data || {}} />
            </div>
          </div>
        </div>
      </div>
      <ActionBar widthClass="col-lg-8 offset-lg-2">
        {prev ? (
          <NakedButton
            id="action-bar-previous-button"
            noPadding
            iconLeft
            icon="west"
            onClick={() => navigate(prev.path)}
          >
            Previous
          </NakedButton>
        ) : null}
        {next ? (
          <PrimaryButton
            id="action-bar-continue-button"
            disabled={!isValid(pathname)}
            onClick={() => navigate(next.path)}
          >
            {continueBtnText || 'Continue'}
          </PrimaryButton>
        ) : null}
      </ActionBar>
    </Loader>
  )
}

export default FindStudents
