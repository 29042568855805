import { useMutation } from '@apollo/client'
import { PrimaryButton } from '@cb/apricot-react-button'
import { Select } from '@cb/apricot-react-forms'
import { MUTATION_SAVE_ASSESSMENTS } from '../../../services/graphql/mutations'
import { ERROR } from '../../../utils/constants'
import { getEducationPeriodDescr } from '../../../utils/date'
import Error from '../../_common/error/Error'
import Toasts from '../../_common/toasts/Toasts'

const VISIBLE_YEARS = 6

const AssessmentActions = ({ currentEdPeriod, onEdPeriodSelection, pendingUpdates = [] }) => {
  const [saves, updateSaves] = useState(0)
  const [save, { loading: saving, error: saveError }] = useMutation(MUTATION_SAVE_ASSESSMENTS, {
    update(cache) {
      cache.modify({
        fields: {
          getAssessmentEvents(existing = []) {
            return existing.map((e) => {
              const { active = e.active } = pendingUpdates.find((s) => s.asmtEventId === e.asmtEventId) || {}
              return { ...e, active }
            })
          },
        },
      })
    },
    onCompleted: () => updateSaves(saves + 1),
  })
  const edPeriodOptions = [...Array(VISIBLE_YEARS).keys()].map((index) => ({
    value: currentEdPeriod - index,
    label: getEducationPeriodDescr(currentEdPeriod - index),
  }))

  return (
    <>
      <div className="row cb-margin-bottom-24">
        <div className="col-sm-6 col-md-4">
          {currentEdPeriod ? (
            <Select
              values={edPeriodOptions}
              label="Education Periods"
              labelNoValue="Select Education Period"
              name="edperiods"
              floating
              onChange={(value) => {
                const period = parseInt(value, 10)
                onEdPeriodSelection(period)
              }}
            />
          ) : null}
        </div>
        <div className="col-sm-6 col-md-8 cb-align-right cb-align-xs-left cb-margin-xs-top-16">
          <PrimaryButton
            loading={saving}
            disabled={pendingUpdates.length === 0 || saving}
            onClick={() =>
              save({
                variables: {
                  assessments: pendingUpdates.map(({ __typename, asmtCategoryName, ...updates }) => updates),
                },
              })
            }
          >
            Update Assessments
          </PrimaryButton>
          <Toasts message="Assessments successfully updated" saves={saves} />
        </div>
      </div>
      {saveError ? (
        <Error
          className="cb-margin-bottom-24"
          container={false}
          message={`${ERROR.ADMIN_SAVE_ASSESSMENTS.message} ${saveError}`}
        />
      ) : null}
    </>
  )
}

AssessmentActions.propTypes = {
  currentEdPeriod: PropTypes.number,
  onEdPeriodSelection: PropTypes.func.isRequired,
  pendingUpdates: PropTypes.array,
}

export default AssessmentActions
