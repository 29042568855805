import { Icon } from '@cb/apricot-react'
import { Checkbox } from '@cb/apricot-react-forms'
import { Column, Table } from '@cb/apricot-react-table'
import { FORMATS, formatDate } from '../../../utils/date'

const AssessmentsTable = ({ events, selectedEvents, onSelection }) => {
  const certifiedEvents = events?.filter((e) => e.certified)

  if (!events) return <p>Select an education period to view assessment events.</p>

  if (events?.length === 0) return <p>There are no available assessments.</p>

  return (
    <Table
      stickyHeader
      responsive
      data={events}
      rowSelected={(r) => selectedEvents.find((s) => s.asmtEventId === r.asmtEventId)}
      rowHighlighted={(r) => !r.active && r.certified}
      initialSort={{ field: 'asmtDate', ascending: false }}
    >
      <Column
        className="cb-align-center"
        field="active"
        ariaLabel="Active Assessments"
        headerComponent={() => (
          <Checkbox
            className="justify-content-center cb-checkbox-white"
            disabled={certifiedEvents.length === 0}
            checked={Boolean(
              certifiedEvents.length &&
                certifiedEvents.every((d) => selectedEvents.find((s) => s.asmtEventId === d.asmtEventId))
            )}
            ariaLabel="Select all certified assessments"
            onChange={(checked) => onSelection(checked ? certifiedEvents : [])}
          />
        )}
        component={({ row }) => (
          <Checkbox
            className="justify-content-center"
            disabled={!row.certified}
            checked={Boolean(selectedEvents.find((s) => s.asmtEventId === row.asmtEventId))}
            ariaLabel={`${row.eventName} ${row.asmtEventId ? 'Active' : ''}`}
            onChange={(checked) =>
              onSelection(
                checked ? [...selectedEvents, row] : selectedEvents.filter((s) => s.asmtEventId !== row.asmtEventId)
              )
            }
          />
        )}
      />
      <Column
        field="asmtDate"
        title="Assessment Date"
        sortable
        component={({ value }) => formatDate(value, FORMATS.mediumdate)}
      />
      <Column
        field="eventName"
        title="Assessment Name"
        sortable
        component={({ value, row }) => `${value} (${row.asmtEventId})`}
      />
      <Column field="asmtCategoryName" sortable title="Assessment Category" />
      <Column
        field="certified"
        sortable
        className="cb-align-center"
        component={({ value, row }) =>
          value ? (
            <>
              <Icon name="check" decorative /> {row.certDate ? formatDate(row.certDate, FORMATS.shortdate) : ''}
            </>
          ) : null
        }
      />
    </Table>
  )
}

AssessmentsTable.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  onSelection: PropTypes.func.isRequired,
  selectedEvents: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default AssessmentsTable
