import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

export const FORMATS = {
  shortdate: 'P', // 05/29/1900
  mediumdate: 'MMM d, yyyy',
  monthday: 'MMMM do', // May 29
  year: 'yyyy',
  month: 'MMMM',
}

export const parse = (d) => (typeof d === 'string' ? parseISO(d) : d)

export const formatDate = (d, formatter = FORMATS.shortdate) => (d ? format(parse(d), formatter) : null)

export const getEducationPeriodDescr = (code) => {
  const num = Number(code)
  return `20${num - 2}-20${num - 1}`
}
