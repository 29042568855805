import { ApolloProvider } from '@apollo/client'
import { useEffect } from 'react'
import { Navigate, matchRoutes, useLocation, useRoutes } from 'react-router-dom'
import { appsyncClient } from '../../services/graphql/client'
import { resetCriteria, setCriteria } from '../../services/session/findstudents'
import { trackPage } from '../../utils/analytics'
import { isInternal } from '../../utils/auth'
import { ADMIN, ROUTES, SITE_NAME } from '../../utils/navigation'
import Navigation from '../admin/navigation/Navigation'
import FindStudents from '../findstudents/FindStudents'

const Routes = () => {
  const location = useLocation()
  const availableRoutes = useMemo(() => (isInternal() ? [...ROUTES, ...ADMIN] : ROUTES), [])
  const routes = useMemo(
    () => [
      { path: '/', element: <FindStudents /> },
      ...availableRoutes.map(({ path, component: Component }) => ({
        path,
        element: <Component />,
      })),
      { path: '*', element: <Navigate to="/" replace /> },
    ],
    [availableRoutes]
  )
  const navigation = useRoutes(routes)

  useEffect(() => {
    setCriteria()
    return () => resetCriteria()
  }, [])

  useEffect(() => {
    const matches = matchRoutes(routes, location)
    const { path } = matches[0]?.route || {}
    const { title } = availableRoutes.find((a) => a.path === path) || {}

    trackPage(path)

    document.title = `${title ? `${title} | ` : ''}${SITE_NAME}`

    window.scrollTo({ top: 0, left: 0 })
  }, [location, routes, availableRoutes])

  return (
    <ApolloProvider client={appsyncClient()}>
      <Navigation />
      <div className="cb-border-top cb-padding-top-32">{navigation}</div>
    </ApolloProvider>
  )
}

export default Routes
