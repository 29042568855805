import FindStudents from '../components/findstudents/FindStudents'
import Roster from '../components/studentroster/StudentRoster'
import TestCriteria from '../components/findstudents/steps/testcriteria/TestCriteria'
import StudentPool from '../components/findstudents/steps/studentpool/StudentPool'
import Organization from '../components/findstudents/steps/organization/Organization'
import StudentDetail from '../components/studentdetails/StudentDetails'
import Assessments from '../components/admin/assessments/Assessments'
import CutScores from '../components/admin/cutscores/CutScores'
import Settings from '../components/admin/settings/Settings'
import { isMultiOrgUser, isSchool } from './auth'
import { generatePath } from 'react-router-dom'

export const SITE_NAME = 'AP Potential'

export const APP_LINKS = {
  roster: '/roster',
  detail: '/roster/student/:catapultId',
  organization: '/search/organization',
  testcriteria: '/search/test-criteria',
  studentpool: '/search/student-pools',
  assessments: '/manage/assessments',
  cutscores: '/manage/cutscores',
  settings: '/manage/settings',
}

export const PAGE_TITLES = {
  search: 'Search for Students',
  roster: 'Student Roster',
  detail: 'Student Details',
  organization: 'Organization',
  testcriteria: 'Test Criteria',
  studentpool: 'Student Pools',
  assessments: 'Assessments',
  cutscores: 'Cut Scores',
  settings: 'Settings',
}

export const FIND_ROUTES = [
  {
    path: APP_LINKS.organization,
    component: Organization,
    title: PAGE_TITLES.organization,
    authCheck: () => !isSchool() || isMultiOrgUser(),
  },
  {
    path: APP_LINKS.testcriteria,
    component: TestCriteria,
    title: PAGE_TITLES.testcriteria,
  },
  {
    path: APP_LINKS.studentpool,
    component: StudentPool,
    title: PAGE_TITLES.studentpool,
    nextPath: APP_LINKS.roster,
    continueBtnText: 'View Student Roster',
  },
]

export const ROUTES = [
  { path: APP_LINKS.roster, component: Roster, title: PAGE_TITLES.roster },
  {
    path: APP_LINKS.organization,
    component: FindStudents,
    title: PAGE_TITLES.organization,
  },
  {
    path: APP_LINKS.testcriteria,
    component: FindStudents,
    title: PAGE_TITLES.testcriteria,
  },
  {
    path: APP_LINKS.studentpool,
    component: FindStudents,
    title: PAGE_TITLES.studentpool,
  },
  {
    path: APP_LINKS.detail,
    component: StudentDetail,
    title: PAGE_TITLES.detail,
  },
]

export const ADMIN = [
  {
    path: '/',
    component: FindStudents,
    title: PAGE_TITLES.search,
  },
  {
    path: APP_LINKS.assessments,
    component: Assessments,
    title: PAGE_TITLES.assessments,
  },
  {
    path: APP_LINKS.cutscores,
    component: CutScores,
    title: PAGE_TITLES.cutscores,
  },
  {
    path: APP_LINKS.settings,
    component: Settings,
    title: PAGE_TITLES.settings,
  },
]

export const getBasePath = (path) => path.split('/')[1]

export const getStudentDetailPath = (catapultId) =>
  generatePath(APP_LINKS.detail, { catapultId: encodeURIComponent(catapultId.split(':')[1]) })
